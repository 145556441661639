import React from "react";
import Search from "./search.js";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { withRouter } from "react-router-dom";
const NoData = Loader.loadBaseComponent("NoData");
const Wrapper = Loader.loadBusinessComponent("BaseLibComponents", "Wrapper");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", "InfiniteScrollLayout");
const Loading = Loader.Loading;
const TitleOptions = Loader.loadBusinessComponent("BaseLibComponents", "TitleOptions");
const { itemWidth, pdWidth } = Dict.map.baselibOptions;
const AccessControlCard = Loader.loadBusinessComponent("Card", "AccessControl");

@withRouter
@Decorator.businessProvider("accessControl", "tab", "user")
@Decorator.businessObserver({ accessControl: ["searchData"], user: ["userHabit"] })
class AccessControlComponent extends React.Component {
  constructor(props) {
    super(props);
    this.urlParams = Utils.queryFormat(props.location.search);
    this.state = {
      list: [],
      size: props.user.userHabit.accessModel || "normal",
      loading: true,
      listKey: Math.random(),
      searchKey: Math.random()
    };
  }

  componentDidMount() {
    const { searchData } = this.props.accessControl;
    let id = this.urlParams.id;
    if (id) {
      LM_DB.get("parameter", id).then(result => {
        if (result) {
          let searchData = result.searchData || {};
          this.mergeSearchData(searchData);
        } else {
          this.reset();
        }
      });
    } else {
      // 没有id时
      this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const accessModel = nextProps.user.userHabit.accessModel;
    if (accessModel && accessModel !== prevState.size && nextProps.storeId === BaseStore.tab.currentId) {
      return { size: nextProps.user.userHabit.accessModel };
    }
    return null;
  }

  /**
   * @desc 查询
   * @param {Boolean} isLoadMore 是否加载更多
   */
  search = (isLoadMore = false) => {
    const accessControlList = this.state.list;
    let options = toJS(this.props.accessControl.searchData);
    let { endTime, endId } = options;
    options.endTime = endId ? +accessControlList[accessControlList.length - 1].captureTime : endTime;
    !isLoadMore &&
      this.setState({
        loading: true
      }); // 加载更多的情况下不出现loading
    // 请求后台数据
    Service.community
      .queryAccessRecords(Utils.accessControlOptions(options))
      .then(result => {
        let list = [];
        let dataList = (this.getLastResultList = result.data.list || []);
        if (isLoadMore) {
          list = this.state.list;
        }
        list = list.concat(dataList);
        this.setState({
          list,
          loading: false,
          listKey: isLoadMore ? this.state.listKey : Math.random()
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   * @param {Boolean} needReplace 是否存储indexDB
   */
  mergeSearchData = (options, needSearch = true, needReplace = false) => {
    if (!options.endId) {
      options.endId = undefined;
    }
    return this.props.accessControl.mergeSearchData(options).then(() => {
      if (needSearch) {
        this.search();
        if (needReplace) {
          let id = Utils.uuid();
          let indexDBObj = {
            id,
            searchData: toJS(this.props.accessControl.searchData)
          };
          LM_DB.add("parameter", indexDBObj).then(() => {
            this.props.tab.goPage({
              moduleName: "accessControl",
              location: this.props.location,
              isUpdate: true,
              data: { id },
              action: "replace"
            });
          });
        }
      }
    });
  };

  /**
   * @desc 刷新
   */
  Refresh = () => {
    // 自定义时间时，时间不变
    let timerTabsActive = this.props.accessControl.searchData.timerTabsActive;
    timerTabsActive === 2 ? this.mergeSearchData({}) : this.mergeSearchData(Utils.getTimerArea(timerTabsActive));
  };

  /**
   * @desc 重置-删除url参数
   */
  reset = () => {
    const { accessControl } = this.props;
    accessControl.initData();
    this.setState({
      searchKey: Math.random()
    });
    accessControl.mergeSearchData(Utils.getTimerArea(accessControl.searchData.timerTabsActive)).then(() => {
      this.search();
      this.props.tab.goPage({
        moduleName: "accessControl",
        location: this.props.location,
        isUpdate: true,
        data: {},
        action: "replace"
      });
    });
  };

  /**
   * @desc 跳转详情
   */
  goDetailPage = item => {
    let { tab, location, accessControl } = this.props;
    const id = item.id;
    window.LM_DB.add("parameter", {
      id: id.toString(),
      data: item,
      searchData: toJS(accessControl.searchData)
    }).then(function() {
      tab.closeTabForName("accessControlDetail");
      tab.goPage({
        moduleName: "accessControlDetail",
        location,
        data: {
          id
        }
      });
    });
  };

  /**
   * @desc 加载更多
   */
  loadMore = () => {
    const { limit } = this.props.accessControl.searchData;
    if (this.getLastResultList.length < limit) {
      // 如果最后一次请求的数据量下于limit数据，代表请求结束
      return;
    }
    let searchDataObj = {
      endId: this.state.list[this.state.list.length - 1].id
    };
    this.mergeSearchData(searchDataObj, false).then(res => {
      this.search(true);
    });
  };

  /**
   * @desc 获取列表高度
   */
  getItemHeight = () => {
    const { size } = this.state;
    let height = 300;
    switch (size) {
      case "small":
        height = 246;
        break;
      case "normal":
        height = 240;
        break;
      case "large":
        height = 268;
        break;
      default:
        break;
    }
    return height;
  };

  /**
   * @desc 切换图片显示大小
   */
  changesize = size => {
    const { user } = this.props;
    this.setState({ size, listKey: Math.random() });
    user.setUserHabit({ accessModel: size });
  };

  render() {
    const { list, loading, listKey, inputValue, size, searchKey } = this.state;
    const { searchData } = this.props.accessControl;
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="access-control-wrapper" title="门禁资源库" reset={this.reset}>
          <Search
            mergeSearchData={(searchData, needSearch) => this.mergeSearchData(searchData, needSearch, true)}
            searchData={searchData}
            inputValue={inputValue}
            inputValueChange={this.inputValueChange}
            key={searchKey}
          />
          <div className="baselib-list-wrapper">
            {!!list.length ? (
              <InfiniteScrollLayout
                key={listKey}
                itemWidth={itemWidth[size]}
                itemHeight={this.getItemHeight()}
                pdWidth={pdWidth[size]}
                data={list}
                hasBackTop={true}
                loadMore={this.loadMore}
                hasLoadMore={!loading}
                renderItem={v => (
                  <AccessControlCard item={v} size={size} onClick={this.goDetailPage.bind(this, v)} searchBtn={true} />
                )}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className="header-little-pagtion">
            <TitleOptions onChange={this.changesize} value={size} Refresh={this.Refresh} />
          </div>
        </Wrapper>
      </Loading>
    );
  }
}
export default AccessControlComponent;
