import React, { Component } from 'react';
const Timer = Loader.loadBusinessComponent('BaseLibComponents', 'Timer')
const PointSelect = Loader.loadBusinessComponent('BaseLibComponents', 'PointSelect')
const RadioGroup = Loader.loadBusinessComponent('BaseLibComponents', 'RadioGroup')
const SearchInput = Loader.loadComponent('SearchInput')
// 开门类型处理
const accessTypes = [].concat(Dict.map.openType, {
  label: "其他",
  value: "other"
})
class Search extends Component {
  /**
   * @desc 编辑查询条件
   */
  change = (options={}, needSearch = true) => {
    this.props.mergeSearchData(options, needSearch)
  }
  render(){
    const { searchData = {} } = this.props
    return (
      <div className='baselib-search-wrapper'>
        <div className='kw-search'>
          <SearchInput
            placeholder='请输入姓名、门禁卡号搜索'
            value={searchData.keywords}
            onChange={keywords => this.change({ keywords })}
          /> 
        </div>
        <div className='small-title'>记录筛选 :</div>
        <Timer 
          value={searchData.timerTabsActive}
          change={this.change}
          onOk={this.change}
          startTime={searchData.startTime}
          endTime={searchData.endTime}
          needSearch={true}
        />
        <PointSelect 
          onChange={this.change}
          selectList={searchData.cameraIds}
          type='accessControl'
        />
        <RadioGroup 
          data={accessTypes}
          label='开门类型'
          iconFont='icon-S_AID_DoorType'
          value={searchData.accessTypes}
          name='accessTypes'
          change={this.change}
        />
      </div>
    )
  }
}

export default Search